import { Pipe, PipeTransform } from '@angular/core';
import { IOptions } from 'sanitize-html';
import * as sanitize from 'sanitize-html';

export type Preset = 'text-formatting-tags'
    | 'link-tags'
    | 'structural-tags';

const PRESET_OPTIONS: Record<Preset, IOptions> = {
    'text-formatting-tags': {
        allowedTags: [
            'b',
            'i',
            'sub',
            'sup',
            'strong',
        ],
    },
    'link-tags': {
        allowedTags: ['a'],
        allowedAttributes: {
            a: [
                'href',
                'target',
            ],
        },
    },
    'structural-tags': {
        allowedTags: [
            'p',
            'ul',
            'ol',
            'li',
        ],
    },
};

@Pipe({
    name: 'stripTagsExcept',
    standalone: true,
})
export class StripTagsExceptPipe implements PipeTransform {
    transform(value: string, presets: Preset[]): string {
        const combinedOptions: IOptions = presets.reduce((options, preset) => {
            const presetOptions = PRESET_OPTIONS[preset];
            options.allowedTags = [
                ...options.allowedTags as [],
                ...(presetOptions.allowedTags as [] ?? []),
            ];
            options.allowedAttributes = {
                ...options.allowedAttributes,
                ...(presetOptions.allowedAttributes ?? {}),
            };

            return options;
        }, { allowedTags: [], allowedAttributes: {} } as IOptions);

        return sanitize(value, combinedOptions);
    }
}
